import React from 'react';

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { isAuthenticated } from './Services/Authentication';
import { ToastContainer } from 'react-toastify';

import Home from './Views/Home';
import Profile from './Views/Profile';
import NotFound from './Views/NotFound';
import Scholarships from './Views/Scholarships';
import Buy from './Views/Buy';
import About from './Views/About';
import Login from './Views/Login';
import Logout from './Views/Logout';
import HowItWorks from './Views/HowItWorks';
import ForgotPassword from './Views/ForgotPassword';
import ResetPassword from './Views/ResetPassword';
import CourseDetails from './Views/CourseDetails';
import Contract from './Views/Contract';
import ContractDefault from './Views/Contract/default';
import Voucher from './Views/Voucher';
import Sellers from './Views/Sellers';
import Faq from './Views/Faq';
import Contact from './Views/Contact';
import PossiblePartiner from './Views/PossiblePartiner';
import Partners from './Views/Partners';
import PrivacyPolicyTermsOfUse from './Views/PrivacyPolicyTermsOfUse';
import Indications from './Views/Indications';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route  {...rest} render={props => (
        isAuthenticated() ?
            (<Component {...props} />)
            :
            (<Redirect to={{ pathname: '/login', state: { from: props.location } }} />)
    )} />
)

const Routes = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/login" component={Login} />
            {/* <Route path="/bolsas" component={Scholarships} /> */}
            <Route path="/bolsas/:category/:city/:course/:institution/:type" component={Scholarships} />
            <Route path="/matricula/:bolsa" component={Buy} />
            <Route path="/quem_somos" component={About} />
            <Route path="/como_funciona" component={HowItWorks} />
            <Route path="/detalhes" component={CourseDetails} />
            <Route path="/recuperar_senha" component={ForgotPassword} />
            <Route path="/resetar_senha" component={ResetPassword} />
            <Route path="/contrato" component={Contract} />
            <Route path="/contrato_default" component={ContractDefault} />
            <Route path="/cupom" component={Voucher} />
            <Route path="/faq" component={Faq} />
            <Route path="/parceiro_divulgacao" component={Partners} />
            <Route path="/portal_vendedor" component={Sellers} />
            <Route path="/fale_conosco" component={Contact} />
            <Route path="/quero-ser-parceiro" component={PossiblePartiner} />
            <Route path="/termos-uso" component={PrivacyPolicyTermsOfUse} />
            <Route path="/indicacoes" component={Indications} />
            <Route path="/logout" component={Logout} />
            <PrivateRoute exact path="/minha_conta" component={Profile} />
            <Route component={NotFound} />
        </Switch>
        <ToastContainer />
    </BrowserRouter>
)

export default Routes;